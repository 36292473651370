import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { graphql, useStaticQuery } from "gatsby"
import Spinner from "../../../assets/images/Spinner_font_awesome.svg"

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, "För kort!")
    .matches(/[\wåäöÅÄÖ]+\s+[\wåäöÅÄÖ]+/iu, "Måste inehålla för och efternamn")
    .required("Du måste skriva ett namn!"),
  address: Yup.string()
    .min(4, "För kort adress")
    .required("Du måste uppge en gatuadress!"),
  zip: Yup.string()
    .min(5, "Ogiltigt postnummer!")
    .matches(/^\d{3}\s*\d{2}\s*$/g, "Postnummer måste vara på formen 12345")
    .required("Du måste skriva ett giltigt postnummer!"),
  city: Yup.string()
    .min(3, "För kort postort")
    .required("Du måste uppge postort!"),
  email: Yup.string().email("Invalid email").required("Du måste uppge e-post!"),
  phone: Yup.string().matches(/^\+?[\d\s-]{10,}$/gm, "Ogiltigt mobilnummer!"),
})

const ValsedelsForm = () => {
  const [state, setState] = useState({ sent: false, status: null })
  const {
    site: {
      siteMetadata: { backendUrl },
    },
  } = useStaticQuery(graphql`
    query ValsedelsQuery {
      site {
        siteMetadata {
          backendUrl
        }
      }
    }
  `)
  if (state.sent) {
    if (state.status === 201) {
      return (
        <div>
          <h2>Din beställning är lagd!</h2>
          <p>Toppen, din beställning är lagd.</p>
          <p>
            Du kommer strax få ett mejl för att bekräfta att du vill ha
            valsedlar hemskickade. Vi kommer sen skicka ut valsedlar till dig i
            augusti!
          </p>
        </div>
      )
    } else {
      return (
        <div>
          <h2>Något gick fel :(</h2>
          Kontakta oss på kyrkoval@alternativforsverige.se om du inte lyckas
          beställa valsedlar här!
        </div>
      )
    }
  }
  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          address: "",
          city: "",
          zip: "",
          phone: "",
          email: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={async values => {
          // same shape as initial values
          try {
            const rawResponse = await fetch(`${backendUrl}/orders`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
            })
            const content = await rawResponse.json()
            const resultCode = rawResponse.status
            setState({ sent: true, status: resultCode })
            if (typeof window !== "undefined") {
              if (window.fbq != null) {
                window.fbq("trackCustom", "Beställt valsedlar")
              }
            }
          } catch (error) {
            setState({ sent: true, status: 500 })
          }
          return
        }}
      >
        {({ errors, touched, values, isSubmitting }) => {
          if (isSubmitting) {
            return (
              <div className="flex flex-col justify-center items-center">
                <Spinner className="animate-spin h-32" />
                <h3>Skickar ...</h3>
              </div>
            )
          }
          return (
            <Form>
              <h1>Fyll i dina uppgifter</h1>
              <div className="flex flex-col mb-4">
                <label htmlFor="name" className="required">
                  Namn:
                </label>
                <div>
                  <Field
                    className="form-input w-full"
                    name="name"
                    placeholder="Arne Svensson"
                    disabled={isSubmitting}
                  />
                  {errors.name && touched.name ? (
                    <div className="error">{errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col mb-4 w-full">
                <label htmlFor="address" className="required">
                  Adress:
                </label>
                <Field
                  className="form-input"
                  name="address"
                  placeholder="Kungsgatan 12"
                  disabled={isSubmitting}
                />
                {errors.address && touched.address ? (
                  <div className="error">{errors.address}</div>
                ) : null}
              </div>
              <div className="md:flex flex-row">
                <div className="flex flex-col mb-4 md:w-1/3 md:mr-4">
                  <label htmlFor="zip" className="required">
                    Postnummer:
                  </label>
                  <Field
                    className="form-input"
                    name="zip"
                    placeholder="123 45"
                    disabled={isSubmitting}
                  />
                  {errors.zip && touched.zip ? (
                    <div className="error">{errors.zip}</div>
                  ) : null}
                </div>
                <div className="flex flex-col mb-4 w-full">
                  <label htmlFor="city" className="required">
                    Postort:
                  </label>
                  <Field
                    className="form-input"
                    name="city"
                    placeholder="Grönköping"
                    disabled={isSubmitting}
                  />
                  {errors.city && touched.city ? (
                    <div className="error">{errors.city}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <label htmlFor="phone">Mobiltelefon:</label>
                <Field
                  className="form-input"
                  name="phone"
                  placeholder="070 123 45 67"
                  disabled={isSubmitting}
                />
                {errors.phone && touched.phone ? (
                  <div className="error">{errors.phone}</div>
                ) : null}
              </div>
              <div className="flex flex-col mb-4">
                <label htmlFor="email" className="required">
                  E-post:
                </label>
                <Field
                  className="form-input"
                  name="email"
                  type="email"
                  placeholder="arne.svensson@mail.com"
                  disabled={isSubmitting}
                />
                {errors.email && touched.email ? (
                  <div className="error">{errors.email}</div>
                ) : null}
              </div>
              <div className="flex flex-row md:justify-end">
                <button
                  type="submit"
                  className={"main-button mt-4 w-full md:w-max"}
                  disabled={isSubmitting}
                >
                  Skicka valsedlar till mig!
                </button>
              </div>
              <small className="required">Obligatoriska uppgifter</small>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ValsedelsForm
