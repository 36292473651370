import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Slanted, { NormalHeading } from "../components/layout/slanted"
import ValsedelsForm from "../components/valsedel/valsedels-form"
import SEO from "../components/SEO"

const valsedlar = ({ data: { wpPage } }) => {
  const imageData = wpPage.featuredImage.node.localFile.childImageSharp.fluid
  return (
    <Layout>
      <SEO
        title={wpPage.title}
        description={wpPage.content}
        image={imageData}
      ></SEO>
      <Slanted imageData={imageData}>
        <NormalHeading white align="right">
          {wpPage.title}
        </NormalHeading>
        <div className=" text-white text-shadow-contrast">
          <div dangerouslySetInnerHTML={{ __html: wpPage.content }} />
        </div>
      </Slanted>
      <Slanted innerClass="bg-white">
        <div style={{ marginTop: "-50px" }}></div>
      </Slanted>
    </Layout>
  )
}

export default valsedlar

export const query = graphql`
  query voteQuery {
    wpPage(slug: { eq: "valsedlar" }) {
      title
      content
      pageFields {
        summary
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
